<template>
  <div class="news-list">
    <ErrorMessage v-if="theError"
                  :message="theError" />
    <p v-if="noNews"
       class="text-center">{{ $t('news.no_news') }}</p>
    <div class="news-list__grid">
      <NewsArticlePreview v-for="article in articles"
                          :key="article.id"
                          :article="article" />
    </div>
    <ButtonSecondary v-if="moreNewsAvailable"
                     class="sm:w-1/3"
                     @click="loadNewsArticles">{{
                      $t('news.load_more')
                    }}
    </ButtonSecondary>
  </div>
</template>
<script setup lang="ts">

const props = defineProps<{
  pageSize: number;
}>();
const { getItems } = useDirectusItems();
const articles = ref<NewsArticle[]>();
const moreNewsAvailable = ref(false);
const page = ref<number>(0)
const theError = ref<string | null>(null)
const locale = usePlatformSettings().locale;
const noNews = ref(false);
const platformName = usePlatformSettings().platformName;
const countryCode = usePlatformSettings().countryCode;

onMounted(() => {
  watch(locale, () => {
    reloadNewsArticles();
  });
});

function reloadNewsArticles() {
  page.value = 0;
  articles.value = [];
  loadNewsArticles();
}

async function loadNewsArticles() {
  const offset = props.pageSize * page.value;
  try {
    const items = await getItems<NewsArticle>({
      collection: "d_news_translations",
      params: {
        limit: props.pageSize + 1,
        offset: offset,
        fields: ['*', 'd_news_id.*', "d_news_id.status"],
        sort: "-d_news_id.date_created",
        filter: {
          languages_id: locale.value,
          d_news_id: {
            status: "published",
            platform: {
              d_platform_id: {
                name: platformName.value
              }
            },
            country: {
              d_country_id: {
                code: countryCode.value
              }
            }
          }
        }
      },
    });
    if (items) {
      if (page.value == 0) {
        moreNewsAvailable.value = items?.length > props.pageSize;
        articles.value = items.slice(0, props.pageSize);
      } else {
        moreNewsAvailable.value = items?.length > props.pageSize;
        articles.value = articles.value?.concat(items.slice(0, props.pageSize));
      }
      if (items.length == 0) {
        noNews.value = true;
      }
      page.value++;
    } else {
      moreNewsAvailable.value = false;
    }
  } catch (error) {
    console.log(error);
    theError.value = "generic";
  }

}

useAsyncData(() => {
  loadNewsArticles();
});

</script>

<style lang="scss">
.news-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 4rem;

  &__grid {
    display: grid;
    align-items: flex-start;
    grid-template-columns: repeat(12, [col-start] 1fr);
    gap: 2rem;
    margin: 2rem 0;
  }

  @include bp-md {
    &__grid {
      gap: 1rem;
    }
  }
}
</style>
